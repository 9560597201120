$(document).ready(function () {


});

$(window).scroll(function () {

    var nav = $('.site-header__navbar');

    if ( $(window).scrollTop() > 30) {
        nav.addClass('site-header__navbar--sticky');
    } else {
        nav.removeClass('site-header__navbar--sticky');
    }

});