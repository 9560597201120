(function($){

    $('#js-menu-toggle').on('click', function(event){
        var element = $(this);
        var navContainer = $('.site-header__navbar');
        var nav = element.next('nav').find('.main-menu');
        var navbar = $('.navbar__bar');

        navContainer.toggleClass('site-header__navbar--active');
        element.toggleClass('navbar__menu-toggle--is-inactive');
        element.toggleClass('navbar__menu-toggle--is-active');
        navbar.toggleClass('navbar__bar--active');
        nav.slideToggle();
    });

})(jQuery);